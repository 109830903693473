<template>
  <div class="book-detail">
    <p class="title">ISO9000标准解说</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书系统的讲述了ISO9001标准的知识，从管理体系的基本概念、术语到管理体系的具体标准条款都有详细解说，是质量管理体系不可多得的教材类书籍。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >中国标准出版社与我司继推出《ISO14001理论与实践丛书》后又共同策划了一套《ISO9001理论与实践丛书》，本书是其中之一。</span
      >
      <br />
      <span>
        从1987版到1994版，再到2000版，ISO9000系列标准带着时代的深深烙印走过了14个年头。如果说10年前ISO9000在中国被蒙上神秘面纱可以被理解的话，则今天应是还其本来面目的时候了，而这首先应立足于对标准的理解。的确，ISO9000标准的编制者集中了世界上的知名专家，是从广泛的立场上深入探讨的结果。但是，为了使其在不同国度、不同行业都能应用，不可避免地造成理解的困难。例如，其用语的高度概括性带来的可能多解性以及做到什么样的程度才算满足标准的要求？对于同样一个ISO9001不同的咨询机构、不同的认证机构、不同的人都可能有不同的解释。因此，实施ISO9000标准应首先理解标准本身的思想和内涵。
        本书充分吸收了多年来ISO9001实施的经验和教训，处于为读者提供理解途径的目的，详细解释了ISO9001和ISO9000所涉及的内容和术语，是一本不可多得的工具书。
      </span>
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>